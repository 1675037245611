<template>
  <v-row>
    <v-col cols="12" md="4">
      <h5 v-t="'page.account.transactions.detail.skinsback.id'" class="grey100--text text-body-2" />
      <p class="mb-0 text--secondary lh-1-2 text-caption" v-text="transaction.data.transaction_id" />
    </v-col>
    <v-col cols="12" md="4">
      <h5 v-t="'page.account.transactions.detail.deposit_value'" class="grey100--text text-body-2" />
      <p v-if="transaction.data.depositValue" class="mb-0 text--secondary lh-1-4 text-body-2">
        {{ transaction.data.depositValue | toScrap }} scrap
      </p>
      <span v-else class="text--disabled">&mdash;</span>
    </v-col>
    <v-col cols="12" md="4">
      <h5 v-t="'page.account.transactions.detail.bonus'" class="grey100--text text-body-2" />
      <p class="mb-0 lh-1-4 text-body-2">
        <span v-if="bonus.value" class="green400--text">
          +{{ bonus.value | toScrap }} ({{ bonus.amount * 100 | toLocaleInt }}%)
        </span>
        <span v-else class="text--disabled">&mdash;</span>
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bonus() {
      return {
        amount: this.transaction.data?.bonus ?? 0,
        value: this.transaction.data?.bonusValue ?? 0,
      }
    },
  },
}
</script>
